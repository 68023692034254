import { createContext, useState, useEffect, useMemo, ReactNode } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { getClientSettings } from '../utils/getClientSettings';

type AxiosProviderProps = {
  children: ReactNode;
};

export type DashboardImage = {
  title: string;
  src: string;
};

type SettingsProps = {
  baseUrl: string | undefined;
  socketUrl: string;
  showAllSidebarItems: boolean;
  shouldInitializeChat: boolean;
  suggestedPrompts: string[];
  dashboardTitle?: string;
  dashboardImages?: DashboardImage[];
  context?: string;
  title?: string;
};

const UploadAxiosContext = createContext<AxiosInstance | null>(null);
const LLMAxiosContext = createContext<AxiosInstance | null>(null);
const AnalysisAxiosContext = createContext<AxiosInstance | null>(null);
const AutogenAxiosContext = createContext<AxiosInstance | null>(null);
const ClientSettingsContext = createContext<SettingsProps | null>(null);

export const AxiosProvider = ({ children }: AxiosProviderProps) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [clientSettings, setClientSettings] = useState<SettingsProps>({
    baseUrl: process.env.API_LLM_BASE_URL,
    socketUrl: 'wss://dev-api.scavenger-ai.com:8002/chat_with_your_data_ws',
    showAllSidebarItems: true,
    shouldInitializeChat: true,
    suggestedPrompts: [],
    dashboardTitle: undefined,
    dashboardImages: undefined,
    context: undefined,
    title: undefined,
  });
  const { getIdTokenClaims, user } = useAuth0();

  // console.log(user)
  useEffect(() => {
    const getIdToken = async () => {
      const tokenClaims = await getIdTokenClaims();
      setToken(tokenClaims?.__raw);
    };
    getIdToken();

    if (user && user.email) {
      const settings = getClientSettings(user.email);
      setClientSettings(settings);
    }
  }, [getIdTokenClaims, user]);

  const uploadAxiosInstance = useMemo(() => {
    if (!token) return null;
    return axios.create({
      baseURL: process.env.API_UPLOAD_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        // Add any additional headers here
      },
    });
  }, [token]);

  const llmAxiosInstance = useMemo(() => {
    if (!token) return null;
    return axios.create({
      baseURL: clientSettings.baseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
        // Add any additional headers here
      },
    });
  }, [token, clientSettings.baseUrl]);

  const analysisAxiosInstance = useMemo(() => {
    if (!token) return null;
    return axios.create({
      baseURL: process.env.API_ANALYSIS_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        // Add any additional headers here
      },
    });
  }, [token]);

  const autogenAxiosInstance = useMemo(() => {
    if (!token) return null;
    return axios.create({
      baseURL: process.env.API_AUTOGEN_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        // Add any additional headers here
      },
    });
  }, [token]);

  const clientSettingsContextValue: SettingsProps = {
    baseUrl: clientSettings.baseUrl,
    socketUrl: clientSettings.socketUrl,
    showAllSidebarItems: clientSettings.showAllSidebarItems,
    shouldInitializeChat: clientSettings.shouldInitializeChat,
    suggestedPrompts: clientSettings.suggestedPrompts,
    dashboardTitle: clientSettings.dashboardTitle,
    dashboardImages: clientSettings.dashboardImages,
    context: clientSettings.context,
    title: clientSettings.title,
  };

  return (

    <ClientSettingsContext.Provider value={clientSettingsContextValue}>
      <UploadAxiosContext.Provider value={uploadAxiosInstance}>
        <LLMAxiosContext.Provider value={llmAxiosInstance}>
          <AnalysisAxiosContext.Provider value={analysisAxiosInstance}>
            <AutogenAxiosContext.Provider value={autogenAxiosInstance}>
              {children}
            </AutogenAxiosContext.Provider>
          </AnalysisAxiosContext.Provider>
        </LLMAxiosContext.Provider>
      </UploadAxiosContext.Provider>
    </ClientSettingsContext.Provider>
  );
};

export {
  UploadAxiosContext,
  LLMAxiosContext,
  AnalysisAxiosContext,
  AutogenAxiosContext,
  ClientSettingsContext,
};
